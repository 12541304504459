import { HeaderBase } from "components";
import { images } from "./constants";

const Header = () => {
  return (
    <HeaderBase
      center
      style={{
        width: "100%",
        marginLeft: 0,
        justifyContent: "center",
      }}
    >
      <img
        style={{ width: "50px", margin: "" }}
        src={images.logo}
        alt="Suit Pods"
      ></img>
    </HeaderBase>
  );
};

export default Header;
