import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const API_BY_STATE: { [key: string]: undefined | string } = {
  PR: process.env.REACT_APP_FUMICO_API_URL, //"https://fumico.herokuapp.com"
  SP: process.env.REACT_APP_ONEPODS_API_URL, // "https://onepods.herokuapp.com"
};

interface InitialState {
  state?: string;
  host: string;
  appVersionCode?: string;
}

const initialState: InitialState = {
  state: "PR",
  host: `${API_BY_STATE.PR}`,
  appVersionCode: undefined,
};

const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    setApiFromState: (state, _: PayloadAction<string>) => {
      // const option = API_BY_STATE[action.payload];
      // state.host = option || state.host;
      state.host = process.env.REACT_APP_FUMICO_API_URL as string;
      state.state = "PR";
    },
    setAppVersionCode: (state, action: PayloadAction<string | null>) => {
      if (!action.payload) return;
      state.appVersionCode = action.payload;
    },
  },
});

export const { actions, reducer } = apiSlice;
