import { apiErrorMessages, endpoints } from "config";
import { useDebounce } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, shoppingCartActions } from "state";
import useRequest from "./useRequest";

const useShoppingCart = (syncPricing: boolean = false) => {
  const [errorMessageShoppingCart, setErrorMessageShoppingCart] =
    useState<string>();

  const token = useSelector((state: RootState) => state.auth.token);

  const {
    products: cartProducts,
    selected,
    updatePricing,
  } = useSelector((state: RootState) => state.shoppingCart);

  const productList = useSelector(
    (state: RootState) => state.products.products
  );

  const dispatch = useDispatch();
  const { post } = useRequest();

  // const saleList = useSelector((state: RootState) => state.products.sale);
  // const mixedList = useSelector((state: RootState) => state.products.mixed);

  const updatePricingTrigger = useDebounce(updatePricing, 300);

  const setQuantity = (id: number, quantity: number) => {
    let product = productList.find((product) => product.id === id);

    if (quantity > 1000) {
      quantity = 1000;
    } else if (quantity < 0) {
      quantity = 0;
    }

    dispatch(
      shoppingCartActions.setQuantity({
        id,
        quantity,
        product,
      })
    );
  };

  const addProduct = (id: number) => {
    let product = productList.find((product) => product.id === id);
    // if (!product) {
    //   product = saleList.find((product) => product.id === id);
    //   if (!product) {
    //     product = mixedList.find((product) => product.id === id);
    //   }
    // }

    dispatch(
      shoppingCartActions.incrementQuantity({
        id,
        change: 1,
        product,
      })
    );
  };

  const removeProduct = (id: number) => {
    let product = productList.find((product) => product.id === id);

    dispatch(
      shoppingCartActions.incrementQuantity({ id, change: -1, product })
    );
  };

  const handleQuantityChange = (event: any) => {
    let value = parseInt(event.target.value || "0");
    if (value < 0) value = 0;
    if (value > 1000) value = 1000;

    dispatch(
      shoppingCartActions.setQuantity({
        ...selected,
        quantity: value.toString(),
      })
    );

    fetchPricing();
  };

  const { active: hasActivePromo, promo: promo_code } = useSelector(
    (state: RootState) => state.activePromo
  );
  const { coordinates } = useSelector(
    (state: RootState) => state.geolocation.selectedAddress
  );

  const fetchPricing = async (coupon?: string) => {
    console.log("calculating pricing...");

    setErrorMessageShoppingCart(undefined);

    if (!cartProducts.length) {
      console.log("cart products empty");
      dispatch(
        shoppingCartActions.setPricing({
          fullprice: 0,
          subtotal: 0,
        })
      );
      return;
    }

    /*
    payload: {
      products: [
        {id: 1, quantity: 10},
        {id: 2, quantity: 5},
        {id: 3, quantity: 2},
      ],
      delivery_coords: { lat: 1, lng: 1 }
    }
    */
    const payload: any = {
      products: cartProducts.map((product) => ({
        id: product.id,
        quantity: product.quantity,
      })),
      delivery_coords: coordinates,
      coupon_code: coupon?.toUpperCase(),
    };

    if (hasActivePromo) {
      payload.promo_code = promo_code;
    }

    let data = await post(endpoints.calculatePricing, payload);

    console.log(data);

    if (data?.coupon === undefined) {
      data = {
        ...data,
        coupon: {
          error: undefined,
        },
      };
    }

    if (!data.coupon.error) {
      dispatch(shoppingCartActions.setPricing(data));
      dispatch(shoppingCartActions.setCupom(coupon));
    } else {
      let message = apiErrorMessages["couponInvalid"];
      setErrorMessageShoppingCart(message);
      dispatch(shoppingCartActions.setCouponError());
    }
  };

  const cartQuantities = cartProducts
    .map((product) => ({ [product.id]: product.quantity }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  const quantity = selected?.quantity || 0;

  useEffect(() => {
    if (syncPricing && updatePricingTrigger) {
      fetchPricing();
    }

    // eslint-disable-next-line
  }, [updatePricingTrigger, token]);

  const clearCupomErrorMessage = () => {
    setErrorMessageShoppingCart("");
  };

  const clearShoppingCart = () => {
    dispatch(shoppingCartActions.cleanShoppingCart());
  };

  const getTotalPrice = () => {
    const prices = cartProducts.map(
      (product) => product.price * product.quantity
    );
    const totalPrice = prices.reduce((acc, product) => {
      return acc + product;
    });

    return totalPrice;
  };

  return {
    products: cartProducts,
    selected,
    setQuantity,
    addProduct,
    cartQuantities,
    quantity,
    fetchPricing,
    errorMessageShoppingCart,
    removeProduct,
    handleQuantityChange,
    clearCupomErrorMessage,
    clearShoppingCart,
    getTotalPrice,
  };
};

export default useShoppingCart;
