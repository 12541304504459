import insta from "assets/insta.svg";
import linked from "assets/linked.svg";
import youtube from "assets/youtube.svg";
import { FUMICO_PHONE_NUMBER } from "config";
import icon_close from "assets/icon_close.svg";
import icon_docs from "assets/icon_docs.svg";
import icon_home from "assets/icon_home.svg";
import icon_message from "assets/icon_message.svg";
import icon_user from "assets/icon_user.svg";
import icon_right from "assets/icon_right.svg";

export const texts = {
  home: "fazer pedido",
  about: "sobre",
  orderHistory: "histórico de pedidos",
  catalog: "catálogo",
  login: "entrar",
  finishSignup: "finalizar cadastro",
  whatsappUrl: `https://api.whatsapp.com/send?phone=${FUMICO_PHONE_NUMBER}&text=`,
  whatsappMessage: "olá! preciso falar com o Suit Pods",
  sac: "whatsapp",
  logout: "desconectar",
  invite: "indique um amigo",
  missingOrders: "finalize {NUM} pedidos para indicar",
};

export const images = {
  insta,
  linked,
  youtube,
  icon_close,
  icon_docs,
  icon_home,
  icon_message,
  icon_user,
  icon_right,
};
